export default {
  props: {
    // Public: Whether the input can receive user interaction.
    disabled: { type: Boolean, default: false },

    // Public: Errors to display below the input, handled by <InputError>.
    error: { type: [Boolean, String, Array], default: false },

    // Public: Hint to be shown next to the input's label as a Tooltip
    hint: { type: [String, Boolean], default: false },

    // Public: Label to display next to the input.
    label: { type: [Boolean, String], default: false },

    // Public: Placeholder to display inside the input when it's empty.
    placeholder: { type: [Boolean, String], default: false },

    // Public: Required fields display a visual indicator, handled by <Label>.
    required: { type: Boolean, default: false },

    // Public: Whether the input can be edited.
    editable: { type: Boolean, default: false },

  },
}
