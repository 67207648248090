export default {
  bed: require('@assets/icons/planned/bed.svg'),
  birthday: require('@assets/icons/planned/birthday.svg'),
  birthdayFill: require('@assets/icons/planned/birthday-fill.svg'),
  brush: require('@assets/icons/planned/brush.svg'),
  burger: require('@assets/icons/planned/burger.svg'),
  caregram: require('@assets/icons/planned/caregram.svg'),
  carenote: require('@assets/icons/planned/carenote.svg'),
  chat: require('@assets/icons/planned/chat.svg'),
  chevronDownDoubleSmall: require('@assets/icons/planned/chevron-down-double-small.svg'),
  chevronLeftDouble: require('@assets/icons/planned/chevron-left-double.svg'),
  chevronLeftDoubleSmall: require('@assets/icons/planned/chevron-left-double-small.svg'),
  chevronLeftSmall: require('@assets/icons/planned/chevron-left-small.svg'),
  chevronRightDoubleSmall: require('@assets/icons/planned/chevron-right-double-small.svg'),
  chevronRightSmall: require('@assets/icons/planned/chevron-right-small.svg'),
  chevronUpSmall: require('@assets/icons/planned/chevron-up-small.svg'),
  designSystemOld: require('@assets/icons/planned/design-system-old.svg'),
  doorRoom: require('@assets/icons/planned/door.svg'),
  editSmall: require('@assets/icons/planned/edit-small.svg'),
  feedbackBullhorn: require('@assets/icons/planned/feedback-bullhorn.svg'),
  filter: require('@assets/icons/planned/filter.svg'),
  filterFilled: require('@assets/icons/planned/filter-filled.svg'),
  indetermined: require('@assets/icons/planned/indetermined.svg'),
  interaction: require('@assets/icons/planned/interaction.svg'),
  keyboardArrowDown: require('@assets/icons/planned/keyboard-arrow-down.svg'),
  keyboardArrowLeft: require('@assets/icons/planned/keyboard-arrow-left.svg'),
  keyboardArrowRight: require('@assets/icons/planned/keyboard-arrow-right.svg'),
  keyboardArrowUp: require('@assets/icons/planned/keyboard-arrow-up.svg'),
  keyboardEnter: require('@assets/icons/planned/keyboard-enter.svg'),
  keyboardEscape: require('@assets/icons/planned/keyboard-escape.svg'),
  keyboardShift: require('@assets/icons/planned/keyboard-shift.svg'),
  keyboardSymbolEnter: require('@assets/icons/planned/keyboard-symbol-enter.svg'),
  keyboardTemplate: require('@assets/icons/planned/keyboard-template.svg'),
  layoutGrid: require('@assets/icons/planned/layout-grid.svg'),
  layoutVertical: require('@assets/icons/planned/layout-vertical.svg'),
  maximize: require('@assets/icons/planned/maximize.svg'),
  mic: require('@assets/icons/planned/mic.svg'),
  minimize: require('@assets/icons/planned/minimize.svg'),
  nurse: require('@assets/icons/planned/nurse.svg'),
  pathway: require('@assets/icons/planned/pathway.svg'),
  permissionFill: require('@assets/icons/planned/permission-fill.svg'),
  pin: require('@assets/icons/planned/pin.svg'),
  record: require('@assets/icons/planned/record.svg'),
  sms: require('@assets/icons/planned/sms.svg'),
  sorry: require('@assets/icons/planned/sorry.svg'),
  speakerLoud: require('@assets/icons/planned/speaker-loud.svg'),
  speakerMute: require('@assets/icons/planned/speaker-mute.svg'),
  todoOutreach: require('@assets/icons/planned/todo-outreach.svg'),
  updown: require('@assets/icons/planned/updown.svg'),
  userFill: require('@assets/icons/planned/user-fill.svg'),
  viewAs: require('@assets/icons/planned/view-as.svg'),
  voicemail: require('@assets/icons/planned/voicemail.svg'),
  waiting: require('@assets/icons/planned/waiting.svg'),
  welcome: require('@assets/icons/planned/welcome.svg'),
  zoomIn: require('@assets/icons/planned/zoom-in.svg'),
  zoomOut: require('@assets/icons/planned/zoom-out.svg'),
  add: require('@assets/icons/animated/add.svg'),
  cipher: require('@assets/icons/animated/cipher-logo.svg'),
  copy: require('@assets/icons/animated/copy.svg'),
  delete: require('@assets/icons/animated/delete.svg'),
  download: require('@assets/icons/animated/download.svg'),
  edit: require('@assets/icons/animated/edit.svg'),
  loading: require('@assets/icons/animated/loading-indicator.svg'),
  preview: require('@assets/icons/animated/preview.svg'),
  show: require('@assets/icons/animated/preview.svg'),
  upload: require('@assets/icons/animated/upload.svg'),
  addModification: require('@assets/icons/static/add.svg'),
  ai: require('@assets/icons/static/ai.svg'),
  alert: require('@assets/icons/static/alert.svg'),
  approve: require('@assets/icons/static/check.svg'),
  archive: require('@assets/icons/static/archive.svg'),
  arrowDown: require('@assets/icons/static/down-arrow.svg'),
  arrowLeft: require('@assets/icons/static/back-arrow.svg'),
  arrowUp: require('@assets/icons/static/up-arrow.svg'),
  brushWide: require('@assets/icons/static/brush-wide.svg'),
  bulb: require('@assets/icons/static/bulb.svg'),
  calendar: require('@assets/icons/static/calendar.svg'),
  careProvider: require('@assets/icons/static/care-provider.svg'),
  changeLog: require('@assets/icons/static/changelog.svg'),
  channels: require('@assets/icons/static/channels.svg'),
  check: require('@assets/icons/static/check.svg'),
  checkFill: require('@assets/icons/static/check-fill.svg'),
  checkmark: require('@assets/icons/static/checkmark.svg'),
  chevronDown: require('@assets/icons/static/chevron-down.svg'),
  chevronLeft: require('@assets/icons/static/chevron-left.svg'),
  chevronRight: require('@assets/icons/static/chevron-right.svg'),
  chevronRightDouble: require('@assets/icons/static/chevron-right-double.svg'),
  close: require('@assets/icons/static/close-small.svg'),
  closeMedium: require('@assets/icons/static/close-medium.svg'),
  configuration: require('@assets/icons/static/configuration.svg'),
  deleteModification: require('@assets/icons/static/status-removed.svg'),
  design: require('@assets/icons/static/design.svg'),
  designSystem: require('@assets/icons/static/design-system.svg'),
  disable: require('@assets/icons/static/disable.svg'),
  disapprove: require('@assets/icons/static/remove.svg'),
  discharge: require('@assets/icons/static/discharge.svg'),
  document: require('@assets/icons/static/filetypes-document.svg'),
  downSmall: require('@assets/icons/static/chevron-down-small.svg'),
  duplicate: require('@assets/icons/static/duplicate.svg'),
  email: require('@assets/icons/static/email.svg'),
  emptySearch: require('@assets/icons/static/sync.svg'),
  enable: require('@assets/icons/static/check.svg'),
  enrollmentHistory: require('@assets/icons/static/list.svg'),
  error: require('@assets/icons/static/error.svg'),
  errorFill: require('@assets/icons/static/error-fill.svg'),
  export: require('@assets/icons/static/export.svg'),
  exportAll: require('@assets/icons/static/export.svg'),
  firstMedical: require('@assets/icons/static/medical.svg'),
  filetreeDir: require('@assets/icons/static/filetree-dir.svg'),
  filetreeDirExpanded: require('@assets/icons/static/filetree-dir-expanded.svg'),
  filetreeEntry: require('@assets/icons/static/filetree-entry.svg'),
  filetreeLastEntry: require('@assets/icons/static/filetree-last-entry.svg'),
  filetreeLevel: require('@assets/icons/static/filetree-level.svg'),
  flagEmpty: require('@assets/icons/static/flag.svg'),
  flagFill: require('@assets/icons/static/flag-fill.svg'),
  flagRemoved: require('@assets/icons/static/flag-removed.svg'),
  fontControl: require('@assets/icons/static/font-control.svg'),
  help: require('@assets/icons/static/help.svg'),
  helpFill: require('@assets/icons/static/help-fill.svg'),
  image: require('@assets/icons/static/filetypes-image.svg'),
  import: require('@assets/icons/static/import.svg'),
  info: require('@assets/icons/static/info.svg'),
  issue: require('@assets/icons/static/fire.svg'),
  kioskCloseButton: require('@assets/icons/static/kiosk-close-button.svg'),
  kioskSettings: require('@assets/icons/static/kiosk-settings.svg'),
  lab: require('@assets/icons/static/flask.svg'),
  launchCalls: require('@assets/icons/static/launch-calls.svg'),
  link: require('@assets/icons/static/link.svg'),
  linkTo: require('@assets/icons/static/link-to.svg'),
  linkToFill: require('@assets/icons/static/link-to-fill.svg'),
  lock: require('@assets/icons/static/lock.svg'),
  logout: require('@assets/icons/static/logout.svg'),
  mail: require('@assets/icons/static/mail.svg'),
  merge: require('@assets/icons/static/merge.svg'),
  minimizeToDock: require('@assets/icons/static/minimize-to-dock.svg'),
  more: require('@assets/icons/static/more.svg'),
  moveFree: require('@assets/icons/static/move-free.svg'),
  moveVertical: require('@assets/icons/static/move-vertical.svg'),
  notifications: require('@assets/icons/static/alert.svg'),
  offline: require('@assets/icons/static/offline.svg'),
  order: require('@assets/icons/static/order.svg'),
  paste: require('@assets/icons/static/paste.svg'),
  pause: require('@assets/icons/static/pause.svg'),
  pdfExport: require('@assets/icons/static/filetypes-pdf.svg'),
  play: require('@assets/icons/static/play.svg'),
  public: require('@assets/icons/static/public.svg'),
  refresh: require('@assets/icons/static/refresh.svg'),
  remove: require('@assets/icons/static/remove.svg'),
  reports: require('@assets/icons/static/report-packages.svg'),
  resend: require('@assets/icons/static/send-fill.svg'),
  reset: require('@assets/icons/static/reset.svg'),
  revert: require('@assets/icons/static/revert-back.svg'),
  round: require('@assets/icons/static/round.svg'),
  rules: require('@assets/icons/static/rules.svg'),
  run: require('@assets/icons/static/run-misc.svg'),
  sad: require('@assets/icons/static/sad.svg'),
  search: require('@assets/icons/static/search.svg'),
  send: require('@assets/icons/static/send.svg'),
  settings: require('@assets/icons/static/configuration.svg'),
  shareFeedback: require('@assets/icons/static/feedback.svg'),
  staffReward: require('@assets/icons/static/reward.svg'),
  staffRewardLegacy: require('@assets/icons/static/staff-reward-legacy.svg'),
  star: require('@assets/icons/static/star.svg'),
  starFill: require('@assets/icons/static/star-fill.svg'),
  statusDirty: require('@assets/icons/static/error-fill.svg'),
  statusError: require('@assets/icons/static/error-fill.svg'),
  statusOk: require('@assets/icons/static/check-fill.svg'),
  statusWarning: require('@assets/icons/static/error-fill.svg'),
  sso: require('@assets/icons/static/permission.svg'),
  stop: require('@assets/icons/static/stop.svg'),
  supervisors: require('@assets/icons/static/user.svg'),
  sync: require('@assets/icons/static/sync.svg'),
  thumbsDown: require('@assets/icons/static/thumbs-down.svg'),
  thumbsUp: require('@assets/icons/static/thumbs-up.svg'),
  time: require('@assets/icons/static/time.svg'),
  test: require('@assets/icons/static/flask-fill.svg'),
  unarchive: require('@assets/icons/static/revert-circle.svg'),
  unit: require('@assets/icons/static/care-provider.svg'),
  unlink: require('@assets/icons/static/unlink.svg'),
  unlock: require('@assets/icons/static/lock-unlocked.svg'),
  unsubscribe: require('@assets/icons/static/logout.svg'),
  updateModification: require('@assets/icons/static/status-changed.svg'),
  user: require('@assets/icons/static/user.svg'),
  userAssign: require('@assets/icons/static/assign.svg'),
  zipExport: require('@assets/icons/static/filetypes-zip.svg'),
  atlasAdd: require('@assets/icons/atlas/add.svg'),
  atlasArticleTask: require('@assets/icons/atlas/article-task.svg'),
  atlasCareFormTask: require('@assets/icons/atlas/care-form-task.svg'),
  atlasClose: require('@assets/icons/atlas/close.svg'),
  atlasFeedback: require('@assets/icons/atlas/feedback.svg'),
  atlasGoTo: require('@assets/icons/atlas/go-to.svg'),
  atlasKeyMetricTask: require('@assets/icons/atlas/key-metric-task.svg'),
  atlasLinkTask: require('@assets/icons/atlas/link-task.svg'),
  atlasLogo: require('@assets/icons/atlas/atlas-logo.svg'),
  atlasMeal: require('@assets/icons/atlas/order-meal.svg'),
  atlasMessage: require('@assets/icons/atlas/chat-message.svg'),
  atlasNotifications: require('@assets/icons/atlas/bell.svg'),
  atlasRecentSearch: require('@assets/icons/atlas/recent-search.svg'),
  atlasServiceRequest: require('@assets/icons/atlas/call-bell.svg'),
  atlasSidebarMenu: require('@assets/icons/atlas/hamburger.svg'),
  atlasSurveyTask: require('@assets/icons/atlas/survey-task.svg'),
  atlasTask: require('@assets/icons/atlas/regular-task.svg'),
  atlasVideoTask: require('@assets/icons/atlas/video-task.svg'),
  jumpTo: require('@assets/icons/atlas/jump-to.svg'),
  clock: require('@assets/icons/floorplans/clock.svg'),
  door: require('@assets/icons/floorplans/door.svg'),
  doorMirrored: require('@assets/icons/floorplans/door-mirrored.svg'),
  elevator: require('@assets/icons/floorplans/elevator.svg'),
  flag: require('@assets/icons/floorplans/flag.svg'),
  food: require('@assets/icons/floorplans/food.svg'),
  keystrokes: require('@assets/icons/floorplans/keystrokes.svg'),
  line: require('@assets/icons/floorplans/line-path.svg'),
  pan: require('@assets/icons/floorplans/pan.svg'),
  path: require('@assets/icons/floorplans/path.svg'),
  patient: require('@assets/icons/floorplans/patient.svg'),
  poweredByCHLogo: require('@assets/icons/floorplans/powered-by-cipherhealth-logo.svg'),
  rectangle: require('@assets/icons/floorplans/rectangle-path.svg'),
  roomPath: require('@assets/icons/floorplans/room-path-solid.svg'),
  roomRectangle: require('@assets/icons/floorplans/room-rectangle-solid.svg'),
  select: require('@assets/icons/floorplans/pointer.svg'),
  staff: require('@assets/icons/floorplans/staff.svg'),
  text: require('@assets/icons/floorplans/text.svg'),
  utensils: require('@assets/icons/floorplans/food.svg'),
  zoomInSimple: require('@assets/icons/floorplans/zoom-in-simple.svg'),
  zoomOutSimple: require('@assets/icons/floorplans/zoom-out-simple.svg'),
  down: require('@assets/icons/fontawesome/regular/arrow-circle-down.svg'),
  pdfResource: require('@assets/icons/fontawesome/regular/file-pdf.svg'),
  up: require('@assets/icons/fontawesome/regular/arrow-circle-up.svg'),
  banner: require('@assets/icons/fontawesome/solid/envelope.svg'),
  bold: require('@assets/icons/fontawesome/solid/bold.svg'),
  boldCheck: require('@assets/icons/fontawesome/solid/check.svg'),
  bookmark: require('@assets/icons/fontawesome/solid/bookmark.svg'),
  camera: require('@assets/icons/fontawesome/solid/camera.svg'),
  caretDown: require('@assets/icons/fontawesome/solid/caret-down.svg'),
  caretRight: require('@assets/icons/fontawesome/solid/caret-right.svg'),
  chevronUp: require('@assets/icons/fontawesome/solid/chevron-up.svg'),
  fileImage: require('@assets/icons/fontawesome/solid/file-image.svg'),
  heartbeat: require('@assets/icons/fontawesome/solid/heartbeat.svg'),
  italic: require('@assets/icons/fontawesome/solid/italic.svg'),
  levelDown: require('@assets/icons/fontawesome/solid/level-down.svg'),
  minus: require('@assets/icons/fontawesome/solid/minus.svg'),
  options: require('@assets/icons/fontawesome/solid/ellipsis-h.svg'),
  paperclip: require('@assets/icons/fontawesome/solid/paperclip.svg'),
  plus: require('@assets/icons/fontawesome/solid/plus.svg'),
  provider: require('@assets/icons/fontawesome/solid/user-md.svg'),
  scrollDown: require('@assets/icons/fontawesome/solid/chevron-down.svg'),
  tag: require('@assets/icons/fontawesome/solid/tag.svg'),
}